[
  { 
    "id": "88ba2032-0d41-45fb-b8b5-0ae8c2e15e6d_29C72F",
    "title": "Keynote by Jan Ozer",
    "subtitle": "STSWE17: Current Status of Codecs in November 2017",
    "url": "https://plus.streamingtech.se/#/asset/88ba2032-0d41-45fb-b8b5-0ae8c2e15e6d_29C72F",
    "poster": "poster-stswe17-ozer.png",
    "hero": true
  },
  { 
    "id": "8cf36a3a-8a89-4e00-a502-c497d8ad9e8d_29C72F",
    "title": "Will Law from Akamai",
    "subtitle": "STSWE17: Low-Latency Streaming Using CMAF Chunks",
    "url": "https://maitv-vod.lab.eyevinn.technology/stswe17-willlaw.m4v/master.m3u8",
    "poster": "poster-stswe17-willlaw.png",
    "hero": true
  },
  { 
    "id": "2067ee32-4a4a-403e-afe1-7b6513dbf116_29C72F",
    "title": "Jai Krishnan from Google / Alliance for Open Media",
    "subtitle": "STSWE17: Current Status of AV1 in November 2017",
    "url": "https://maitv-vod.lab.eyevinn.technology/stswe17-jaikrishnan.m4v/master.m3u8",
    "poster": "poster-stswe17-jaikrishnan.png",
    "hero": true
  },
  { 
    "id": "0a25a0d0-a5fd-4f8a-9f9a-7b03d47cce2f_29C72F",
    "title": "Arash Pendari from Vionlabs",
    "subtitle": "STSWE17: The Role of Metadata in Content Discovery Improving UX and Reducing Churn",
    "url": "https://maitv-vod.lab.eyevinn.technology/stswe17-arash.mp4/master.m3u8",
    "poster": "poster-stswe17-arash.png",
    "hero": true
  },
  { 
    "id": "stswe17-linden",
    "title": "Marcus Lindén from Bonnier Broadcasting",
    "subtitle": "STSWE17: Experiences from Server-Side Dynamic Ad Insertion",
    "url": "https://maitv-vod.lab.eyevinn.technology/stswe17-linden.mp4/master.m3u8",
    "poster": "poster-stswe17-linden.png"
  },
  { 
    "id": "stswe17-lindqvistskaneby",
    "title": "Carl Lindqvist from Bonnier Broadcasting and Johan Skaneby from Eyevinn",
    "subtitle": "STSWE17: Benefits of Using CRF-capped VOD Encoding",
    "url": "https://maitv-vod.lab.eyevinn.technology/stswe17-lindqvistskaneby.mp4/master.m3u8",
    "poster": "poster-stswe17-lindquistskaneby.png"
  },
  { 
    "id": "stswe17-somolinos",
    "title": "José Somolinos from Accedo",
    "subtitle": "STSWE17: Designing User Experiences for VR",
    "url": "https://maitv-vod.lab.eyevinn.technology/stswe17-somolinos.mp4/master.m3u8",
    "poster": "poster-stswe17-jose.png"
  },
  { 
    "id": "stswe17-springalljones",
    "title": "David Springall from Yospace and Andy Jones from Adstream",
    "subtitle": "STSWE17: User Experience and Efficiency Challenges Presented by Programmatic Ad Selling",
    "url": "https://maitv-vod.lab.eyevinn.technology/stswe17-springalljones.mp4/master.m3u8",
    "poster": "poster-stswe17-springalljones.png"
  },
  { 
    "id": "stswe17-widlund",
    "title": "Fredrik Widlund from Swedish Television",
    "subtitle": "STSWE17: How SVT built their in-house CDN",
    "url": "https://maitv-vod.lab.eyevinn.technology/stswe17-widlund.mp4/master.m3u8",
    "poster": "poster-stswe17-widlund.png"
  },
  { 
    "id": "stswe17-cedronius",
    "title": "Anders Cedronius from Net Insight",
    "subtitle": "STSWE17: Delays in Broadcast Networks",
    "url": "https://maitv-vod.lab.eyevinn.technology/stswe17-cedronius.m4v/master.m3u8",
    "poster": "poster-stswe17-cedronius.png"
  },
  { 
    "id": "stswe17-fastly",
    "title": "John Håkansson and Markus Eskola from Fastly",
    "subtitle": "STSWE17: Building Origins that Scale",
    "url": "https://maitv-vod.lab.eyevinn.technology/stswe17-fastly.mp4/master.m3u8",
    "poster": "poster-stswe17-fastly.png"
  },
  { 
    "id": "stswe17-elena",
    "title": "Elena Malakhatka from VR Sci Tech",
    "subtitle": "STSWE17: The Hero's Journey",
    "url": "https://maitv-vod.lab.eyevinn.technology/stswe17-elena.m4v/master.m3u8",
    "poster": "poster-stswe17-elena.png"
  }
]